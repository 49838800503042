import Head from "next/head";
import Header from "./Header";
import Footer from "./Footer";
import styles from "./Layout.module.scss";

const Layout = (props) => (
  <div>
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <title>Fundlytics - Portal</title>
    </Head>
    <Header />
    <div className={styles.main}>
      <div className="container fill">
        <div className="row">
          <div className="col bottom-buffer">{props.children}</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default Layout;
