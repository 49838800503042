import Link from "next/link";
import Image from "next/image";
import { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";
import styles from "./Header.module.scss";

const Brand = () => (
  <div className="navbar-brand">
    <Link href={"/"}>
      <a>
        <Image width={50} height={50} src="/a-logo2.svg" alt="Fundlytics" />
      </a>
    </Link>
  </div>
);

const NavigationItem = ({ url, title, subtitle }) => {
  return (
    <NavItem>
      <Link href={url}>
        <a className="nav-link">
          <h2 className="title">{title}</h2>
          <h3 className="subtitle d-none d-lg-block">{subtitle}</h3>
        </a>
      </Link>
    </NavItem>
  );
};

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar expand="md" className="menu">
      <div className="container">
        <Brand />
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavigationItem
              url="/"
              title="Dashboard"
              subtitle="Summary & Statistics"
            />
            <NavigationItem
              url="/portfolio"
              title="Portfolio"
              subtitle="Show invested funds"
            />
            <NavigationItem
              url="/search"
              title="Search"
              subtitle="Search for funds"
            />
            <NavigationItem
              url="/account"
              title="Account"
              subtitle="Update your details"
            />
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

const Header = () => (
  <div className="sticky-top">
    <header className={styles.header}>
      <Navigation />
      <div className="ribbon"></div>
    </header>
  </div>
);

export default Header;
