import styles from "./Footer.module.scss";

const Footer = (props) => (
  <footer className={styles.footer}>
    <div className="ribbon"></div>
    <div className="menu">
      <div className="container-fluid">{"Copyright info"}</div>
    </div>
  </footer>
);

export default Footer;
